<template>
    <div id="slot">
        <div class="wrapper">
            <template v-if="gameUrl">
                <iframe :src="gameUrl" scrolling="no" frameborder="0" allow=“autoplay;clipboard-write;fullscreen”></iframe>
            </template>
        </div>
    </div>
</template>
<script>
    import Auth from "@/services/auth.js";
    import API from "@/services/api.js";
    import { md5 } from "js-md5";

    export default {
        data() {
            // https://eu-server.ssgportal.com/GameLauncher/Loader.aspx?GameCategory=JetX&GameName=JetX&Token=TESTTOKEN&PortalName=rahisibet&ReturnUrl=http://google.com
            return {
                url:'https://eu-server.ssgportal.com/GameLauncher/Loader.aspx',
                gameCategory:'JetX',
                gameName:'JetX',
                portalName:'rahisibet',
                returnUrl:'https://rahisibet.com',
                gameUrl:null,
                hash:"bf55710e3bc11b8b48f00b63dc9c279b",
            }
        },
        async mounted() {
            const user = Auth.getAuthUser();
            let md5hash = md5(`${this.hash}-${this.makeid(10)}`);
            let jtoken = JSON.parse(localStorage.getItem("jetxtoken"));

            if(jtoken) {
                let str =`${this.url}?GameCategory=${this.gameCategory}&GameName=${this.gameName}&Token=${jtoken}&PortalName=${this.portalName}&Lang=${this.$i18n.locale}`;
                this.gameUrl = str;
            } else {
                localStorage.setItem('jetxtoken', JSON.stringify(md5hash));
                await API.sendToken(md5hash,user.user.id);
                let str =`${this.url}?GameCategory=${this.gameCategory}&GameName=${this.gameName}&Token=${md5hash}&PortalName=${this.portalName}&Lang=${this.$i18n.locale}`;
                this.gameUrl = str;
            }

            
            
        },
        methods:{
            makeid: function(length) {          
                let result = '';
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                const charactersLength = characters.length;
                let counter = 0;
                while (counter < length) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                    counter += 1;
                }
                return result;
            }
        },
    }
</script>
<style scoped>
    .main-body {
        padding-top: 120px !important;
    }
    iframe {
        min-width: 95vw;
        min-height: 85vh;
        width: 100%;
        height: 100%;
    }
    .reset {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    .full-screen {
        display: flex;
        justify-content: space-between;
        padding: 10px 5px
    }
    .full-screen span {
        cursor: pointer;
    }
</style>