import { Subject } from 'rxjs'
import axios from "axios"
import  {
    BETS_URL,
    BETS_BY_DATE_URL,
    BETS_BY_ID_URL,
    PLACE_NORMAL_BET_URL,
    PLACE_STAGING_BET_URL,
    MINI_JACKPOT_GAMES_URL,
    MEGAJACKPOT_BET_URL,
    SHARE_NORMAL_BET_URL,
    SHARE_SLIP_BET_URL,
    BETS_SHARE_URL
}  from "./api_endpoints"

export default class BetslipService {
    static subject = new Subject();
    static remove_subject = new Subject();
    static message = new Subject();
    static betslip = new Subject()

    static megajackpot = new Subject()
    static minijackpot = new Subject()

    static addBetslip(betslip) {
        if (localStorage.getItem("betslip") === null) {
            let firstslip = [];
            firstslip.push(betslip);
            localStorage.setItem('betslip', JSON.stringify(firstslip));

            this.subject.next();
        }
        else 
        {
            let existingbetslip = JSON.parse(localStorage.getItem("betslip"));
            let betcheck = existingbetslip.find(x => x.id == betslip.id);

            if (betcheck) {
                let index = existingbetslip.indexOf(betcheck);
                if ( betcheck.market.oddId == betslip.market.oddId ) {
                   
                    existingbetslip.splice(index, 1)                  
                    if (existingbetslip.length == 0) 
                    {
                        localStorage.removeItem("betslip");
                    } 
                    else 
                    {
                        localStorage.setItem('betslip', JSON.stringify(existingbetslip));
                    }

                    this.remove_subject.next();
                    this.message.next(false);
                } 
                else 
                {
                    existingbetslip[index].market = betslip.market;
                    localStorage.setItem('betslip', JSON.stringify(existingbetslip));
                    this.subject.next();
                }
            }
            else {
                if (existingbetslip.length < 20) {
                    existingbetslip.push(betslip);
                    localStorage.setItem('betslip', JSON.stringify(existingbetslip));

                    this.message.next(false);
                    this.subject.next();
                }
                else {
                    this.message.next(true);
                }
            }
        }
        
        let betslip_data = JSON.parse(localStorage.getItem("betslip"))

        this.betslip.next(betslip_data);
    }

    static getAddedBetslip () { 
        return this.subject.asObservable()
    }

    static getRemovedBetslip () { 
        return this.remove_subject.asObservable()
    }

    static getBetcount() {
        return this.message.asObservable()
    }

    static getBetslip() {
        return this.betslip.asObservable()
    }

    static setSubjectBetslip() {
        
        if (localStorage.getItem("betslip") !== null) {
            let betslip_data = JSON.parse(localStorage.getItem("betslip"));
            this.betslip.next(betslip_data);
        }
        else{
            this.betslip.next(null);
        }
    }

    static popBestlip(selectedBet) {
        let betslip_data = JSON.parse(localStorage.getItem("betslip"));
        let betcheck = betslip_data.find(x => x.id == selectedBet);

        if (betcheck) {
            let index = betslip_data.indexOf(betcheck);
            betslip_data.splice(index, 1);

            localStorage.setItem('betslip', JSON.stringify(betslip_data));

            this.remove_subject.next();
            this.message.next(false);
        }

        if (betslip_data.length == 0) {
            localStorage.removeItem("betslip");
        }
      

        let r_betslip_data = JSON.parse(localStorage.getItem("betslip"));        
        
        this.betslip.next(r_betslip_data);
    }

    static clearBetslip( type) {
        switch (type) {
            case 'mega_jackpot':
                localStorage.removeItem(type)
                this.megajackpot.next(null)
                break;
            case 'daily_jackpot':
                localStorage.removeItem(type)
                this.megajackpot.next(null)
                break;
            default:
                localStorage.removeItem("betslip")
                this.subject.next();
                this.betslip.next(null)
                break;
        }       
    }

    static onOddsChange(data){
        let existingbetslip = JSON.parse(localStorage.getItem("betslip"));
        if(existingbetslip) {
            data.oddsChange.forEach(el => {
                let betcheck = existingbetslip.find(x => x.id == el.pick);
                if (betcheck) 
                {
                    betcheck.market.odd = el.odds
                }
            });
        }

        localStorage.setItem('betslip', JSON.stringify(existingbetslip))

        let betslip_data = JSON.parse(localStorage.getItem("betslip"))
        this.betslip.next(betslip_data);
    }

    static onBetstop(data){
        let existingbetslip = JSON.parse(localStorage.getItem("betslip"));
        if(existingbetslip) {
            data.forEach(el => {
                let betcheck = existingbetslip.find(x => x.id == el);
                if(betcheck)
                {
                    let index = existingbetslip.indexOf(betcheck);
                    existingbetslip.splice(index, 1);

                    if(existingbetslip.length == 0) {
                        localStorage.removeItem("betslip")
                    } else {
                        localStorage.setItem('betslip', JSON.stringify(existingbetslip));
                    }
                        
                    
                    this.remove_subject.next();
                }
            });

            let r_betslip_data = JSON.parse(localStorage.getItem("betslip"));
            this.betslip.next(r_betslip_data)
        }
    }

    // jackpots
    static addJackpotBetslip(betslip,type) {
        localStorage.setItem(type, JSON.stringify(betslip))        
        this.setJackpotSubject(type)
    }

    static setJackpotSubject(type) {
        let betslip_data = []
        betslip_data = JSON.parse(localStorage.getItem(type))
        if (betslip_data) { 
            this.megajackpot.next(betslip_data) 
        } else { 
            this.megajackpot.next(null)
        }
        // switch (type) {
        //     case 'mega_jackpot':
                
        //         break;

        //     case 'daily_jackpot':
        //         betslip_data = JSON.parse(localStorage.getItem(type))
        //         if (betslip_data) { this.minijackpot.next(betslip_data) } else { this.minijackpot.next(null) }
        //         break;
        
        //     default:
        //         break;
        // }
    }

    static getJackpotBetslip() {
        
        return this.megajackpot.asObservable();
        // if (type == 'mega_jackpot' ) {
        //     return this.megajackpot.asObservable()
        // } else {
        //     return this.minijackpot.asObservable()
        // }
    }

    static addSingleJackpotBetslip(betslip,type) {
        if (localStorage.getItem(type) === null) {
            let firstslip = [];
            firstslip.push(betslip);
            localStorage.setItem(type, JSON.stringify(firstslip));
        }
        else {
            let existingbetslip = JSON.parse(localStorage.getItem(type));
            let betcheck = existingbetslip.find(x => x.id == betslip.id);

            if (betcheck) {
                let index = existingbetslip.indexOf(betcheck);
                if (betcheck.selected_odd_id == betslip.selected_odd_id) 
                {
                    existingbetslip.splice(index, 1);

                    if (existingbetslip.length == 0) {
                        // let firstslip = [];
                        localStorage.removeItem(type);
                    } else {
                        localStorage.setItem(type, JSON.stringify(existingbetslip));
                    }
                } else {

                    existingbetslip[index].selected_odd_outcome = betslip.selected_odd_outcome;
                    existingbetslip[index].selected_market = betslip.selected_market;
                    existingbetslip[index].selected_mkt_id = betslip.selected_mkt_id;
                    existingbetslip[index].selected_odd_id = betslip.selected_odd_id;
                    existingbetslip[index].selected_marketId = betslip.selected_marketId;
                    existingbetslip[index].selected_handicap = betslip.selected_handicap;

                    localStorage.setItem(type, JSON.stringify(existingbetslip));
                }
            }
            else{
                if (existingbetslip.length < 18) {
                    existingbetslip.push(betslip);
                    localStorage.setItem(type, JSON.stringify(existingbetslip));
                }
            }
        }

        this.setJackpotSubject(type)
    }

    static popSingleJackpotBestlip(selectedBet,type) {
        let betslip_data = JSON.parse(localStorage.getItem(type));
        let betcheck = betslip_data.find(x => x.id == selectedBet.id);

        if (betcheck) {
            let index = betslip_data.indexOf(betcheck);
            betslip_data.splice(index, 1);

            localStorage.setItem(type, JSON.stringify(betslip_data));
        }

        if (betslip_data.length == 0) {
            localStorage.removeItem(type);
        }

        this.setJackpotSubject(type)
    }


    // Protected Betslip history
    //Get bet history
    static async getBetHistory(payload) {
        const games = await axios.get(BETS_URL,{ params: payload.post});
        return games.data;
    }

    //Get single bet
    static async getBetById(payload) {
        try {
            const games = await axios.get(BETS_BY_ID_URL + '/' + payload.bet_id);
            return games.data.data;
        } catch (error) {
            return null;
        }
        
    }

    //Get bet by Date range
    static async getBetByDateRange(payload) {
        const games = await axios.post(BETS_BY_DATE_URL,{ 'from': payload.date.from,"to": payload.date.to },{params:{...payload.post}});
        return games.data;
    }

    //Get pagination data
    static async getPagination(payload) {
        let bets = null
        switch (payload.type) {
            case "get":
                bets = await axios.get( payload.path );
                break;
            case "post":
                bets = await axios.post( payload.path, { 'from': payload.time.from,"to": payload.time.to } );      
                break;        
            default:
                break;
        }
        return bets.data;
    }

    /*
    * Place normal bet, minijackpot and megajackpot
    *
    *
    */
    static async placeBet (betslip) {
        const res = await axios.post(
            PLACE_NORMAL_BET_URL,
            {
                "use_bonus": betslip.use_bonus,
                "amount":betslip.amount,
                "bettype":betslip.bettype,
                "picks":betslip.picks
            }
        )
        return res.data
    }

    static async placeBetB (betslip) {
        const res = await axios.post(
            PLACE_STAGING_BET_URL,
            {
                "use_bonus": betslip.use_bonus,
                "amount":betslip.amount,
                "bettype":betslip.bettype,
                "picks":betslip.picks
            }
        )
        return res.data
    }

    static async placeSharedBet (payload) {
        const res = await axios.post(
            SHARE_NORMAL_BET_URL,
            payload
        )
        return res.data
    }

    static async getSharedBet (payload) {
        const res = await axios.post(
            SHARE_SLIP_BET_URL,
            payload
        )
        return res.data
    }

    static async getActiveSharedBet (payload) {
        const res = await axios.post(
            BETS_SHARE_URL,
            payload
        );
        return res.data;
    }

    static async placeMiniJackpot (betslip) {
        const res = await axios.post(
            MINI_JACKPOT_GAMES_URL,
            {
                "amount":betslip.amount,
                "bettype":betslip.bettype,
                "picks":betslip.picks,
                "use_bonus":betslip.use_bonus
            }
        )

        return res.data
    }

    static async placeMegaJackpot (betslip) {
        const res = await axios.post(
            MEGAJACKPOT_BET_URL,
            {
                "amount":betslip.amount,
                "bettype":betslip.bettype,
                "picks":betslip.picks,
                "use_bonus":betslip.use_bonus
            }
        )

        return res.data
    }
    // End

}