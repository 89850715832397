<template>
    <div v-frag >
        <template v-if="type == 'header'">
            <form class="form-inline" @submit.prevent="submit" >
                <div class="form-group">
                    <div v-if="authStatus">
                        <div class="invalid-feedback" v-if="authStatus === 'error'">
                            <div>{{ $t('header.invalid-credentials') }}</div>
                        </div>

                        <div class="invalid-feedback" v-if="authStatus === 'loading'">
                            <div>...{{  authStatus }}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <input type="text" class="form-login form-control" v-model.trim="$v.phone.$model" placeholder="79 411 411">
                    
                </div>
                <div class="form-group" style="position:relative">
                    <input :type="passwordFieldType" id="password" class="form-login form-control" v-model.trim="$v.password.$model"    placeholder="Enter password" maxlength="6">
                    <i class="fa fa-eye field-icon" aria-hidden="true" v-if="!show" @click="switchVisibilty"></i>
                    <i class="fa fa-eye-slash field-icon" aria-hidden="true" v-if="show" @click="switchVisibilty"></i>
                    
                </div>
                <div class="form-group">
                    <button class="btn btn-default btn-login" id="btn-login_">{{ $t('header.login') }}</button>
                </div>
            </form>
            <div class="register" v-if="$v.phone.$error || $v.password.$error">

                <span v-if="$v.phone.$error" class="invalid-feedback" required>
                    <span v-if="!$v.phone.numeric">{{ $t('header.numbers-only') }}</span>&nbsp;
                    <span v-if="!$v.phone.required">{{ $t('header.required') }}</span>&nbsp;
                    <span v-if="!$v.phone.minLength">{{ $t('header.phone-number-error') }}</span>&nbsp;
                </span>&nbsp;&nbsp;

                <span  v-if="$v.password.$error" class="invalid-feedback">
                    <span v-if="!$v.password.required">{{ $t('header.required') }}</span>&nbsp;
                    <span v-if="!$v.password.minLength">{{ $t('header.password-error-less') }}</span>&nbsp;
                    <span v-if="!$v.password.maxLength">{{ $t('header.password-error-more') }}</span>&nbsp;
                </span>

            </div>
        </template>
        <div class="well well-lg registration" v-else> 
            <div class="row">
                <div class="col-sm-offset-4 col-sm-4">
                    <form class="form-horizontal"  @submit.prevent="submit">
                            <div class="text-center">
                                <h2>{{ $t('login.login') }}</h2>
                                <div v-if="authStatus">
                                    <div class="invalid-feedback" v-if="authStatus === 'error'">
                                        <div>{{ $t('header.invalid-credentials') }}</div>
                                    </div>

                                    <div class="invalid-feedback" v-if="authStatus === 'loading'">
                                        <div>...{{  authStatus }}</div>
                                    </div>
                                </div>
                            </div>
                    
                            <div class="form-group">
                                <label class="" for="reg-code">{{ $t('register.phone-number') }}:</label>
                                <div class="">
                                    <!-- <input type="text" class="form-login form-control" v-model.trim="$v.phone.$model" placeholder="79 411 411" :class="{ 'is-invalid' : $v.phone.$error }"> -->
                                    <vue-tel-input 
                                        v-model="phone"
                                        :defaultCountry="geo.country_code2"
                                        mode="international"
                                        :inputOptions="options"
                                    ></vue-tel-input>
                                </div>
                            </div>
                    
                            <div class="form-group">
                                <label class="" for="pwd">{{ $t('register.password') }}:</label>
                                <div class="" style="position: relative;">
                                    <input :type ="passwordFieldType" id="passwordL" class="form-login form-control" :placeholder="this.$t('header.place_pass')" v-model.trim="$v.password.$model" :class="{ 'is-invalid': $v.password.$error }" 
                                    required maxlength="6">
                                    <i class="fa fa-eye field-icon" aria-hidden="true" v-if="!show" @click="switchVisibilty"></i>
                                    <i class="fa fa-eye-slash field-icon" aria-hidden="true" v-if="show" @click="switchVisibilty"></i>
                                    <div v-if="$v.password.$error" class="invalid-feedback">
                                        <div v-if="!$v.password.required">{{ $t('header.required') }}</div>
                                        <div v-if="!$v.password.minLength">{{ $t('header.password-error-less') }}</div>
                                        <div v-if="!$v.password.maxLength">{{ $t('header.password-error-more') }}</div>
                                    </div>
                                </div>
                            </div>
                    
                            <div class="form-group">
                                <div class="">
                                    <button type="submit" class="btn btn-default">{{ $t('header.login') }}</button>
                                    <router-link class="btn btn-default" to ="forgot-password">{{ $t('header.forgot-password') }}?</router-link>
                                </div>
                            </div>
                    </form>
                </div>
            </div>
        </div>

        
    </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapActions,mapGetters } from "vuex";

export default {
    name: 'login',
    props: {
        type: String
    },
    data() {
        return {
            phone: '',
            password: '',
            error: null,
            show: false,
            passwordFieldType: 'password',
            options: {
                placeholder: this.$i18n.t('header.place_phone')
            }
        }
    },
    validations: {
        phone: {
            required:required,
        },
        country: {
            required:required,
        },
        password: {
            required:required,
            minLength: minLength(6),
            maxLength: maxLength(6),
        }
    },
    methods: {
        ...mapActions({
            login: 'auth_user',
        }),
        submit:  function() {   
            let str = this.phone;
            str = str.replace(/\s/g, "");
            this.phone = str.replace(/\+/g, "");

            this.login({ "phone": this.phone, "password": this.password }).then( () => {
                
                if (this.$route.query.redirect != null) {
                    this.$router.push( this.$route.query.redirect)
                } else {
                    if (this.authStatus != 'error') {
                        this.$router.push("/");
                    }
                }
            }).catch( err => console.log(err))             
        },
        switchVisibilty: function() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
            this.show = this.passwordFieldType === 'password' ? true : false
        },
    },
    computed: {
        ...mapGetters({
            authStatus: "authStatus",
            geo:'geo',
        })
    }
}
</script>

<style>
    .form-login.form-control {
        background-color: white;
        color: #145795;
    }
    .field-icon {
        margin-left: -30px;
        margin-top: -25px;
        position: absolute;
        z-index: 2;
        top: 35px;
        color: #000;
        right:10px;
    }
    .registration .vti__input {
        color : var(--bg-dark-medium)
    }
    .registration .vti__dropdown-item {
        color : var(--bg-dark-medium) !important;
    }
    .registration .vti__dropdown {
        background-color: var(--a-text);
    }
</style>