<template>
  <div class="well well-lg registration">
      <div class="row">
          <div class="col-sm-offset-4 col-sm-4">
              <form class="form-horizontal" @submit.prevent="submit">
                  <div class="text-center">
                      <h2>{{ $t('verification.verify') }}</h2>
                      <p>{{ $t('verification.verify-message') }}</p>
                      <div v-if="error">
                          <div class="invalid-feedback">
                              <div>{{ $t('verification.verify-error') }}</div>
                          </div>
                      </div>
                  </div>

                  <br>
                  <div class="form-group">
                      <label class="control-label" for="email">{{ $t('register.phone-number') }}:</label>
                      <vue-tel-input 
                            v-model="user.phone"
                            :defaultCountry="geo.country_code2"
                            mode="international"
                            :inputOptions="options"
                        ></vue-tel-input>
                  </div>

                  <div class="form-group">
                      <label class="control-label " for="reg-code">{{ $t('verification.verify-code') }}:</label>
                      <div class="">
                          <input type="text" class="form-control" v-model.trim="$v.user.verificationCode.$model" placeholder="123456" maxlength="6">
                          <div v-if="$v.user.verificationCode.$error" class="invalid-feedback" required>
                              <div v-if="!$v.user.verificationCode.numeric">{{ $t('header.numbers-only') }}</div>
                              <div v-if="!$v.user.verificationCode.required">{{ $t('header.required') }}</div>
                              <div v-if="!$v.user.verificationCode.numeric">
                                  {{ $t('verification.reset-code-error') }}
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="form-group">
                      <div class="">
                          <button type="submit" class="btn btn-default">{{ $t('verification.verify-button') }}</button>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
</template>

<script>
import { required, minLength,maxLength,numeric } from 'vuelidate/lib/validators';
import AuthService from "@/services/auth";
import { mapGetters } from "vuex";

export default {
    name: 'verification',
    data() {
        return {
            user: {
                phone: '',
                verificationCode: ''
            },
            error:'',
            options: {
                placeholder: this.$i18n.t('header.place_phone')
            },
        }
    },
    validations: {
        user: {
            phone: {
                required,
            },
            verificationCode: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(6),
                numeric
            }
        }
    },
    methods:{
        submit: async function() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            
            let str = this.user.phone;
            str = str.replace(/\s/g, "");
            this.user.phone = str.replace(/\+/g, "");

            await AuthService.verification(this.user)
            .then( res => {
                if(!res.success) {
                    this.error = true
                } else {
                    this.error = false
                    this.$swal.fire('','','success')
                    this.$router.push("/login")
                }
            })
            .catch( err => {
                if (err.message) {
                    this.error = true
                }
            })
        }
    },
    computed: {
    ...mapGetters({
        geo:'geo',
    })
  },
}
</script>

<style scoped>
    .well .btn {
        margin-left: 0px;
    }
</style>