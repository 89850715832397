<template>
  <div class="well well-lg registration">
    <div class="row">
        <div class="col-sm-offset-4 col-sm-4">
            <form class="form-horizontal"  @submit.prevent="submit">
                <div class="text-center">
                    <h2>{{ $t('reset-password.reset-password') }}</h2>
                    <p>{{ $t('reset-password.reset-code-message') }}</p>
                    <div v-if="error">
                        <div class="invalid-feedback">
                            <div>{{ $t('reset-password.reset-code-error') }}</div>
                        </div>
                    </div>
                </div>

                <br>
                <div class="form-group">
                    <label class="control-label " for="email">{{ $t('register.phone-number') }}:</label>
                    <vue-tel-input 
                        v-model="user.phone"
                        :defaultCountry="geo.country_code2"
                        mode="international"
                        :inputOptions="options"
                    ></vue-tel-input>
                </div>

                <div class="form-group">
                    <label class="control-label "
                        for="reg-code">{{ $t('reset-password.reset-code') }}:</label>
                    <div class="">
                      <input type="text" class="form-control" v-model.trim="$v.user.resetCode.$model" :placeholder="this.$t('header.place_pass')" maxlength="6" :class="{ 'is-invalid' : $v.user.resetCode.$error }">
                          <div v-if="$v.user.resetCode.$error" class="invalid-feedback" required>
                              <div v-if="!$v.user.resetCode.numeric">{{ $t('header.numbers-only') }}</div>
                              <div v-if="!$v.user.resetCode.required">{{ $t('header.required') }}</div>
                              <div v-if="!$v.user.resetCode.minLength">
                                  {{ $t('reset-password.reset-code-num-error') }}
                              </div>
                              <div v-if="!$v.user.resetCode.maxLength">
                                  {{ $t('reset-password.reset-code-num-error') }}
                              </div>
                          </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label" for="pwd">{{ $t('reset-password.new-password') }}:</label>
                    <div class=""  style="position: relative;">
                        <input :type="passwordFieldType" class="form-control" v-model.trim="$v.user.password.$model" :class="{ 'is-invalid' : $v.user.password.$erros }" maxlength="6" placeholder="123456">
                        <i class="fa fa-eye field-icon" aria-hidden="true" v-if="!show" @click="switchVisibilty"></i>
                        <i class="fa fa-eye-slash field-icon" aria-hidden="true" v-if="show" @click="switchVisibilty"></i>
                        <div v-if="$v.user.password.$error" class="invalid-feedback">
                            <!-- <div *ngIf="f.password.errors.pattern">{{ 'header.numbers-only' | translate }}</div> -->
                            <div v-if="!$v.user.password.required">{{ $t('header.required') }}</div>
                            <div v-if="!$v.user.password.minLength">{{ $t('header.password-error-less') }}</div>
                            <div v-if="!$v.user.password.maxLength">{{ $t('header.password-error-more') }}</div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label " for="pwd">{{ $t('register.password-confirm') }}:</label>
                    <div class=""  style="position: relative;">
                        <input :type="passwordFieldType" class="form-control" v-model.trim="$v.user.confirmPassword.$model" :class="{ 'is-invalid' : $v.user.confirmPassword.$erros }" maxlength="6" placeholder="123456">
                        <i class="fa fa-eye field-icon" aria-hidden="true" v-if="!show" @click="switchVisibilty"></i>
                        <i class="fa fa-eye-slash field-icon" aria-hidden="true" v-if="show" @click="switchVisibilty"></i>
                        <div v-if="$v.user.confirmPassword.$error" class="invalid-feedback" required>
                            <div v-if="!$v.user.confirmPassword.required">{{ $t('header.required') }}</div>
                            <!-- <div *ngIf="f.confirmPassword.errors.pattern">{{ 'header.numbers-only' | translate }}</div> -->
                            <div v-if="!$v.user.confirmPassword.minLength">{{ $t('header.password-error-less') }}</div>
                            <div v-if="!$v.user.confirmPassword.maxLength">{{ $t('header.password-error-more') }}</div>
                            <div v-if="!$v.user.confirmPassword.sameAsPassword">{{ $t('register.password-confirm-error') }}</div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="form-group">                    
                    <button type="submit" class="btn btn-default btn-block">{{ $t('reset-password.change-password') }}</button>
                </div>
            </form>
        </div>
    </div>


</div>
</template>

<script>
import { required, minLength, maxLength,sameAs } from 'vuelidate/lib/validators'
import AuthService from "@/services/auth";
import { mapGetters } from "vuex";

export default {
    name: 'reset-password',
    props: {
        msg: String
    },
    computed: {
        ...mapGetters({
            geo:'geo',
        })
    },
    data() {
        return {
            user:{
                phone: '',
                resetCode: '',
                password: '',
                confirmPassword:'',
            },
            options: {
                placeholder: this.$i18n.t('header.place_phone')
            },
            error: '',
            passwordFieldType: 'password',
            show: false
        }
    },
    validations: {
        user: {
            phone: {
                required:required,
            },
            resetCode: {
                required:required,
                minLength: minLength(6),
                maxLength: maxLength(6),
            },
            password: {
                required:required,
                minLength: minLength(6),
                maxLength: maxLength(6),
            },
            confirmPassword: {
                required:required,
                minLength: minLength(6),
                maxLength: maxLength(6),
                sameAsPassword: sameAs('password')
            }
        }
    },
    methods:{
        submit: function() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            let str = this.user.phone;
            str = str.replace(/\s/g, "");
            this.user.phone = str.replace(/\+/g, "");
            
            AuthService.reset_password(this.user)
            .then( res => {
                if(res.message != 'success') {
                    this.$swal.fire(this.$i18n.t('reset-password.reset-code-error'),'','error' );
                    this.error = true
                } else {
                    this.error = false
                    this.$swal.fire('','','success' );
                    this.$router.push("/login")
                }
            })
            .catch( err => { 
                this.$swal.fire(this.$i18n.t('reset-code-error'),'','success' );
                if (err.message && err.errors) {
                    this.error = false;
                }
            })
        },
        switchVisibilty: function() {
            this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
            this.show = this.passwordFieldType === 'password' ? true : false
        }
    }
}
</script>

<style >
    .well .btn {
        margin-left: 0px;
    }
    .field-icon {
        margin-left: -30px;
        margin-top: -25px;
        position: absolute;
        z-index: 2;
        top: 35px;
        color: #000;
        right:10px;
    }
    .registration .vti__input {
        color : var(--bg-dark-medium)
    }
    .registration .vti__dropdown-item {
        color : var(--bg-dark-medium)
    }
    .registration .vti__dropdown {
        background-color: var(--a-text);
    }
</style>